@use '../../utils/functions/' as *;
@use '../../utils/mixins/' as *;


.featured {
  padding: 72px 0;

  .title {
    padding-left: 64px;
    margin-bottom: 98px;

    &::before {
      background-image: url('../images/icons/snowflake.svg');
    }
  }

  [hidden] {
    display: none;
  }

  &__hidding {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    border-bottom: 1px solid var(--color-black-30);
  }

  [role="tab"] {
    padding: 0;
    padding-bottom: 18px;
    position: relative;
    width: 100%;
    font-weight: var(--fw-500);
    line-height: 1.27;
    font-size: 20px;
    text-align: center;
    background-color: transparent;
    border-bottom: 2px solid transparent;
    color: var(--color-black-30);
    transition: all 0.4s;

    &:hover,
    &:focus {
      color: var(--color-black);
    }

  }

  button[aria-selected="true"] {
    border-bottom: 2px solid var(--color-black);
  }


  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 400px;
    padding-top: 86px;
    gap: 40px;
  }

  &__information {
    text-align: left;
    max-width: 600px;

    h3 {
      text-align: left;
      font-size: 24px;
      font-weight: var(--fw-700);
      margin-bottom: 16px;
      line-height: 1.5;
      color: var(--color-black);
    }

    p {
      margin-bottom: 16px;
      color: var(--color-black-50);
      line-height: 1.2;

      &:not(:last-of-type) {
        font-weight: var(--fw-700);
        color: var(--color-black-30);
      }
    }

    .btn {
      color: var(--color-blue-150) !important;
      padding: 4px;
      max-width: 117px;
      text-align: start;
      display: inline-block;
      margin: 0;

      &::after {
        right: 4px;
      }

      &:hover,
      &:focus {
        color: var(--color-black) !important;

        &::after {
          background-image: url('../images/icons/arrow-next-black.svg');
        }
      }
    }
  }

  @include media(tablet) {

    &__content  {

      img {
        max-width: 400px;
        aspect-ratio: 16 / 10;
      }
    }
  }

  @include media(mobile-l) {

    &__hidding {
      gap: 20px;
    }

    [role="tab"] {
      font-size: 16px;
    }

    &__content  {
      flex-direction: column;

      img {
        order: 0;
        max-width: 100%;
        width: 100%;
      }
    }

    &__information  {
      order: 1;
      max-width: 100%;
    }
  }

  @include media(mobile-m) {

    &__content  {
      padding-top: 40px;
    }

  }

}