@use '../utils/functions/' as *;
@use '../utils/mixins/' as *;

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 15;
  width: 100%;
  padding: 24px 0;

  &__inner {
    background: transparent;
  }

  .logo {
    display: none;
    align-items: center;
    justify-content: center;

    @include media(tablet) {
      display: flex;
    }
  }

  .nav__btns {

    .btn {
      padding: 18px 32px;
      margin: 0;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }

    .btn--user {
      color: var(--color-white);
      border: 2px solid transparent;
      border-image: url('../images/button-bg.png') 1 round;
    }

    .request-btn {
      border: 2px solid transparent;
      color: var(--color-black) !important;
      background-color: var(--color-white);
    }
  }

  &.hide {
    transform: translateY(calc(-100% - 30px));
  }

  @include media(tablet) {
    min-height: unset;
  }
}
