@use '../../utils/functions/' as *;
@use '../../utils/mixins/' as *;


.activities {

  img {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
  }

  &__detailed {
    margin-bottom: 48px;

    li {
      color: var(--color-black-80);
      font-size: 24px;
      line-height: 1.5;

      &:not(:last-child) {
        margin-bottom: 36px;
      }
    }
  }

  &__content {
    padding: 86px 0;

    h2 {
      margin-bottom: 24px;
      color: var(--color-black-900);
      line-height: 1.3;
      text-transform: none;

      &::before {
        display: none;
      }
    }

    p {
      color: var(--color-black-80);

      &:not(:last-child) {
        margin-bottom: 48px;
      }
    }

    img {
      max-width: 1200px;
      margin-bottom: 48px;
    }
  }

}