@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.contact {
  padding: 108px 0 110px;
  position: relative;
  background-image: url('../images/home/contact-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: var(--color-black-80);
  }

  .container {
    position: relative;
    z-index: 3;
  }

  .title {
    padding-left: 64px;
    margin-bottom: 16px;
    color: var(--color-white);

    &::before {
      background-image: url('../images/icons/snowflake-white.svg');
    }
  }

  h3 {
    font-family: var(--font-family-secondary);
    font-weight: var(--fw-400);
    font-size: clamp(1.75rem, 0.885rem + 3.462vw, 4rem);
    line-height: 1.24;
    margin-bottom: 86px;

    span {
      color: var(--color-blue-125);
    }
  }

  &__form {
    display: flex;
    max-width: 703px;
    width: 100%;
  }

  label {
    width: 100%;
  }

  input {
    font-weight: var(--fw-500);
    max-width: 534px;
    border: 1px solid var(--color-white);
    padding: 18px 32px;
    width: 100%;
    color: var(--color-gray-50);
    background-color: transparent;
    font-size: 20px;
    line-height: 1.2;

    &::placeholder {
      color: var(--color-white-opacity-80);
    }
  }

  .btn {
    border: 2px solid transparent;
    max-width: 169px;
    padding: 15px 48px;
    background-color: var(--color-white);

    &:hover,
    &:focus {
      color: var(--color-blue-150);
    }
  }

  @include media(tablet) {

    h3 {
      margin-bottom: 40px;
    }
  }

  @include media(mobile-l) {
    padding: 40px 0;
  }

  @include media(mobile-m) {

    &__form {
      flex-direction: column;
      gap: 10px;

      .btn {
        max-width: unset;
        width: 100%;
      }
    }
  }

}
