@use '../../utils/functions/' as *;
@use '../../utils/mixins/' as *;

.discover {
  padding: 72px 0 176px;

  h2 {
    font-family: var(--font-family-secondary);
    font-weight: var(--fw-400);
    font-size: 100px;
    font-size: clamp(2.25rem, -0.607rem + 7.619vw, 6.25rem);
    line-height: 1.24;
    margin-bottom: 99px;
    text-transform: lowercase;

    &::before {
      display: none;
    }
  }

  &__images {
    overflow-x: hidden;
    max-width: 1440px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
  }

  @include media(tablet) {
    padding: 40px 0;

    h2 {
      margin-bottom: 20px;
    }
  }
  
  @include media(mobile-s) {

    h2 {
      font-size: 28px;
    }
  }

}