@use '../../utils/functions/' as *;
@use '../../utils/mixins/' as *;


.cultural {
  padding: 86px 0 65px;

  &__insights {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 2px solid var(--color-gray-75);
    padding-bottom: 91px;
    margin-bottom: 64px;
    gap: 20px;
  }

  &__content {
    max-width: 421px;
    width: 100%;

    h2 {
      line-height: 1.5;
      margin-bottom: 16px;

      &::before {
        display: none;
      }
    }

    p {
      font-size: 20px;
      color: var(--color-black-850);
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }

  &__item {
    max-width: 380px;
    width: 100%;
    padding-top: 62px;

    .title {
      font-size: 24px;
      color: var(--color-blue-800);
      margin-bottom: 16px;
      text-transform: none;
      
      &:before {
        width: 38px;
        height: 38px;
        top: -62px;
        transform: translateY(0);
        background-image: url('../images/icons/snowflake-grey.svg');
      }
    }

    p {
      color: var(--color-black-850);
      max-width: 370px;
    }
  }

  @include media(tablet-wide) {

    img {
      max-width: 500px;
    }
   }

  @include media(tablet) {
    padding: 40px 0;

    &__insights {
     flex-direction: column;
     padding-bottom: 40px;
    }

    img {
      width: 100%;
      max-width: unset;
    }

    &__content {
      max-width: unset;
    }
  }
  @include media(mobile-l) {

    &__items {
      grid-template-columns: repeat(1, 1fr);
    }

    &__item {
      max-width: unset;

      p {
        max-width: unset;
      }
    }


  }

}