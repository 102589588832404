@use '../../utils/functions/' as *;
@use '../../utils/mixins/' as *;

.signature {
  padding: 72px 0 118px;

  .title {
    padding-left: 64px;
    margin-bottom: 16px;

    &::before {
      background-image: url('../images/icons/snowflake.svg');
    }
  }

  .description {
    max-width: 709px;
    margin-bottom: 87px;
    width: 100%;
    color: var(--color-black-850);
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;

    img {
      margin-bottom: 30px;
      min-height: 480px;
    }

    h3 {
      text-align: center;
      color: var(--color-black);
      font-weight: var(--fw-700);
      font-size: 24px;
      line-height: 1.5;
      margin-bottom: 40px;
    }

    a {
      font-weight: var(--fw-500);
      font-size: 20px;
      line-height: 1.2;
      &::after {
        right: 0px;
      }

      &:hover,
      &:focus {
        color: var(--color-black);

        &::after {
          background-image: url('../images/icons/arrow-next-black.svg');
        }
      }
    }
  }

  &__item {
    text-align: center;
  }

  @include media(desktop) {

    &__items {

      h3 {
        margin: 0 auto 30px;
        max-width: 320px;
      }
    }
  }

  @include media(tablet) {

    &__items {
      grid-template-columns: 1fr;

      img {
        max-width: unset;
        width: 100%;
        aspect-ratio: 16 / 8;
      }

      h3 {
        max-width: unset;
      }
    }
  }

  @include media(mobile-m) {
    padding: 40px 0;
  }

}