@use '../utils/functions/' as *;
@use '../utils/mixins/' as *;

h1,
h2,
h3 {
  overflow: hidden;
  width: fit-content;
  font-weight: var(--fw-600);
  line-height: 1.2;
  letter-spacing: 0px;
  color: var(--color-white);
}

h1 {
  font-size: clamp(2rem, 0.143rem + 9.286vw, 136px);
  line-height: 1.24;

  @include media(mobile-l) {
    font-size: 32px;
  }
}

h2,
.title {
  position: relative;
  overflow: visible;
  font-size: 32px;
  color: var(--color-blue-800);
  text-transform: uppercase;
  line-height: 1.5;
  
  &:before {
    content: '';
    position: absolute;
    width: 48px;
    height: 48px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-image: url('../images/icons/snowflake.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
}

h3 {
  font-size: 24px;
  width: 100%;
}

p {
  font-size: 18px;
  line-height: 1.5;
  font-weight: var(--fw-400);
  letter-spacing: 0;
}

.description {
  font-size: 24px;
}


