/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */

@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Poppins:wght@400;500;600;700&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');


:root {
  --content-width: 1296px;
  --content-width--small: 1200px;

  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-width--small: calc(var(--content-width--small) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Poppins", sans-serif;
  --font-family-secondary: "Libre Baskerville", serif;
  --font-family-third: "Work Sans", sans-serif;

  // font-weight
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  // color
  --color-white: #fff;
  --color-white-50: #f7faff;
  --color-white-75: #dbe9ff;

  --color-gray-50: rgba(255, 255, 255, 0.5);
  --color-gray-75: #e7dfdc;

  --color-black: #000;
  --color-black-30: rgba(0, 0, 0, 0.3);
  --color-black-50: rgba(0, 0, 0, 0.5);
  --color-black-80: rgba(0, 0, 0, 0.8);

  --color-black-850: rgba(20, 19, 19, 0.5);
  --color-black-900: #141313;


  --color-blue-100: #7e9fd6;
  --color-blue-125: #89abe3;
  --color-blue-150: #507fbb;
  --color-blue-175: #4778c8;
  --color-blue-250: rgba(80, 127, 187, 0.3);
  --color-blue-800: #0a1c33;
  --color-blue-850: #05101d;

  //  radius
  --radius-btn: 8px;
  --radius-main: 20px;

}



