@use '../../utils/functions/' as *;
@use '../../utils/mixins/' as *;

.hero {
  min-height: 900px;

  h1 {
    font-family: var(--font-family-secondary);
    font-weight: var(--fw-400);
    font-size: 136px;
    max-width: 753px;
    line-height: 1.24;
    position: absolute;
    top: 100px;
    right: -53px;
    z-index: 2;

    span {
      white-space: nowrap;
    }

  }

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 900px;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: var(--header-height);

    &--tours {

      .hero__wrapper {
        gap: 97px;
      }

      h1 {
        position: relative;
        top: 0;
        right: 0;
        max-width: 680px;
      }

      .hero__content {
        padding-top: 187px;
      }
    }

    &--home {

      h2 {
        text-transform: none;
      }

    }
  }

  .hero__title {

    &--small {
      font-size: 100px;

    }

  }

  &__intro {
    position: relative;
    width: 100%;
    max-width: 821px;

    img {
      min-height: 650px;
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: var(--color-black-50);
    }
  }

  &__picture  {
    position: relative;
    width: 100%;
    max-width: 845px;

    img {
      min-height: 650px;
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: var(--color-black-50);
    }

    &--tours {
      max-width: 596px;

      &::before {
        display: none;
      }
    }
  }

  &__wrapper {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    gap: 107px;
    padding: 0;

    &--home {
      gap: 132px;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    height: 100%;
    padding-top: 50px;
    max-width: 416px;
    width: 100%;

    &--tours {
      max-width: 100%;
    }

    h2 {
      margin: 0 0 16px;
      font-size: 24px;
      max-width: 322px;
      width: 100%;
      line-height: 1.5;
      color: var(--color-white);

      &::before {
        display: none;
      }
    }

    p {
      width: 100%;
      margin-bottom: 182px;
      color: var(--color-white);
      max-width: 322px;
    }

    .btn {
      position: relative;
      max-width: 416px;
      width: 100%;
      font-size: 32px;
      line-height: 1.2;
      justify-content: flex-start;
      padding: 33px 75px;
      white-space: nowrap;
      background-color: transparent;
      color: var(--color-white) !important;
      margin: 0;
      border: 4px solid transparent;
      border-image: url('../images/button-bg.png') 1 round;

      &::after {
        content: '';
        position: absolute;
        right: 75px;
        top: 50%;
        display: block;
        width: 64px;
        height: 64px;
        background-image: url('../images/icons/arrow-white.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        translate: 0 -50%;
      }
    }
  }

  // style for tour page

  &--tour {

    .hero__inner {
      position: relative;
      flex-direction: row;
      justify-content: space-between;
      gap: 20px;
    }
    .hero__wrapper {
      justify-content: flex-end;
      gap: 146px;
    }

    a {
      font-size: 20px;
      line-height: 1.2;
      font-weight: var(--fw-500);
      display: inline-block;
      padding-left: 36px;
      margin-bottom: 34px;
      color: var(--color-white);
      text-transform: none;

      &::before {
        width: 24px;
        height: 24px;
        background-image: url('../images/icons/arrow-back.svg');
      }
    }

    h1 {
      position: relative;
      top: 0;
      right: 0;
      left: 0;
      transform: translate(0);
      font-family: var(--font-family-primary);
      font-size: 32px;
      font-weight: var(--fw-600);
      line-height: 1.5;
      margin-bottom: 34px;
      max-width: 630px;
    }

    .hero__content {
      max-width: 630px;
      padding-top: 124px;
    }

    p {
      font-size: 24px;
      max-width: 630px;
    }

    img {
      min-height: 619px;
    }

    .hero__picture {
      align-self: flex-end;
      max-width: 592px;

      &::before {
        display: none;
      }
    }
  }

  @include media(desktop-wide) {

    h1 {
      font-size: clamp(2rem, 0.143rem + 9.286vw, 8.5rem);
    }

    &--tour {

       h1 {
         font-size: 32px;
       }

    }

    .hero__title {

      &--small {
        font-size: clamp(2rem, 0.786rem + 6.071vw, 6.25rem);
      }

    }

    &__title {

      img {
        min-height: unset;
      }
    }

    &__wrapper {
      padding: 40px 15px 40px 0;
    }

    &--tour {

      .hero__wrapper {
        padding: 0;
        padding-left: 15px;
      }

    }
  }

  @include media(desktop) {
    min-height: 750px;

    &__inner {
      min-height: 750px;
    }

    &__intro {

      img {
        min-height: 550px;
      }
    }

    &--tour {
      min-height: 850px;

      .hero__inner {
        min-height: 850px;
      }

      .hero__wrapper {
        gap: 60px;
      }

    }

    &__title, &__picture  {

      img {
        min-height: 500px;
        aspect-ratio: 16 / 8;
      }

      &--tour {

        img {
          min-height: 619px;
        }
      }
    }

    &__content {

      p {
        margin-bottom: 80px;
      }

      &--tour {

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  @include media(tablet-wide) {

    &__content {
      max-width: 350px;

      &--tours {
        max-width: 100%;
      }

      p {
        margin-bottom: 40px;
      }


      &--tour {

        p {
          margin-bottom: 0;
        }
      }

      .btn {
        padding: 30px 48px;

        &::after {
          right: 38px;
        }
      }
    }
  }

  @include media(tablet) {
    min-height: 700px;

    &__inner {
      min-height: 700px;
      position: relative;
    }

    &__wrapper {
      flex-direction: column;
      gap: 40px;
      padding: 40px 0;

      &--home {

        .hero__content {
          padding: 0 15px;
          max-width: 400px;

          h2, p {
            max-width: 400px;
          }

        }
      }
    }

    &__intro {
      max-width: 100%;

      img {
        width: 100%;
        min-height: unset;
        aspect-ratio: 16 / 12;
      }
    }

    &--tour {
      
      .hero__wrapper {
        padding: 0;
      }
    }

    h1 {
      top: 50%;
      left: 50%;
      right: 0;
      transform: translate(-50%, -50%);
      text-align: center;
    }

    &__content {
      align-self: center;
    }

    &__title {
      align-self: center;
    }

    &__title, &__picture  {

      img {
        min-height: unset;
      }
    }

    &__picture {
      max-width: 100%;

      img {
        aspect-ratio: 16 / 12;
        width: 100%;
      }

      &--tours {
        align-self: center;
      }
    }

    &__inner--tours {

      h1 {
        top: 0;
        left: 0;
        right: 0;
        transform: translate(0);
        margin: 0 auto;
      }

      .hero__wrapper {
        gap: 40px;
      }

      .hero__content {
        padding: 0;
        align-self: center;
        text-align: center;
      }

    }

    &--tour {

      .hero__picture {
        max-width: 100%;
        order: 0;
      }
      .hero__content {
        padding: 0 15px;
        order: 1;
      }

      h1 {
        text-align: left;
        position: static;
        transform: translate(0, 0);
      }
    }
  }

  @include media(mobile-l) {
    min-height: 500px;

    &__content {
      max-width: unset;

      h2 {
        max-width: unset;
      }

      p {
        max-width: unset;
      }

      .btn {
        max-width: none;
        width: 100%;
      }

    }

    &__inner--tours {

      .hero__container {
        gap: 20px;
      }
    }

    &__inner {
      min-height: 500px;
    }


    &--tour {

      .hero__content {

        a {
          font-size: 26px;
          margin-bottom: 20px;
        }

        p {
          font-size: 20px;
        }
      }

      h1 {
        font-size: 26px;
        margin-bottom: 20px;
      }
    }
  }

  @include media(mobile-m) {
    min-height: 400px;

    &__content {
      padding-top: 20px;
    }

    &__inner {
      min-height: 400px;
    }

  }

  @include media(mobile-s) {


    &__content {

      .btn {
        padding: 30px 20px;

        &::after {
          right: 20px;
        }
      }
    }


  }

}



