@import "https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Poppins:wght@400;500;600;700&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap";
:root {
  --content-width: 1296px;
  --content-width--small: 1200px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --container-width--small: calc(var(--content-width--small)  + (var(--container-offset) * 2));
  --font-family-primary: "Poppins", sans-serif;
  --font-family-secondary: "Libre Baskerville", serif;
  --font-family-third: "Work Sans", sans-serif;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --color-white: #fff;
  --color-white-50: #f7faff;
  --color-white-75: #dbe9ff;
  --color-gray-50: #ffffff80;
  --color-gray-75: #e7dfdc;
  --color-black: #000;
  --color-black-30: #0000004d;
  --color-black-50: #00000080;
  --color-black-80: #000c;
  --color-black-850: #14131380;
  --color-black-900: #141313;
  --color-blue-100: #7e9fd6;
  --color-blue-125: #89abe3;
  --color-blue-150: #507fbb;
  --color-blue-175: #4778c8;
  --color-blue-250: #507fbb4d;
  --color-blue-800: #0a1c33;
  --color-blue-850: #05101d;
  --radius-btn: 8px;
  --radius-main: 20px;
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  background-color: var(--color-white);
  font-optical-sizing: auto;
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1;
}

.page__body {
  min-height: var(--vh);
  min-width: 320px;
}

.page.open {
  overflow: hidden;
}

.container {
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
  width: 100%;
  margin: 0 auto;
}

.container--small {
  max-width: var(--container-width--small);
}

.site-container {
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.background-dark {
  background-color: var(--color-blue-850);
}

.background-light {
  background-color: var(--color-white-50);
}

.logo {
  width: 100%;
  max-width: 257px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  width: 100%;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  font-weight: var(--fw-600);
  letter-spacing: 0;
  color: var(--color-white);
  width: fit-content;
  line-height: 1.2;
  overflow: hidden;
}

h1 {
  font-size: clamp(2rem, .143rem + 9.286vw, 136px);
  line-height: 1.24;
}

@media only screen and (width <= 768px) {
  h1 {
    font-size: 32px;
  }
}

h2, .title {
  color: var(--color-blue-800);
  text-transform: uppercase;
  font-size: 32px;
  line-height: 1.5;
  position: relative;
  overflow: visible;
}

h2:before, .title:before {
  content: "";
  background-image: url("../images/icons/snowflake.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

h3 {
  width: 100%;
  font-size: 24px;
}

p {
  font-size: 18px;
  line-height: 1.5;
  font-weight: var(--fw-400);
  letter-spacing: 0;
}

.description {
  font-size: 24px;
}

.header {
  z-index: 15;
  width: 100%;
  padding: 24px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header__inner {
  background: none;
}

.header .logo {
  justify-content: center;
  align-items: center;
  display: none;
}

@media only screen and (width <= 992px) {
  .header .logo {
    display: flex;
  }
}

.header .nav__btns .btn {
  margin: 0;
  padding: 18px 32px;
}

.header .nav__btns .btn:not(:last-child) {
  margin-right: 24px;
}

.header .nav__btns .btn--user {
  color: var(--color-white);
  border: 2px solid #0000;
  border-image: url("../images/button-bg.png") 1 round;
}

.header .nav__btns .request-btn {
  background-color: var(--color-white);
  border: 2px solid #0000;
  color: var(--color-black) !important;
}

.header.hide {
  transform: translateY(calc(-100% - 30px));
}

@media only screen and (width <= 992px) {
  .header {
    min-height: unset;
  }
}

.nav {
  position: relative;
}

.nav__inner {
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  display: flex;
}

.nav__inner .logo {
  display: block;
}

@media only screen and (width <= 992px) {
  .nav__inner .logo {
    display: none;
  }

  .header .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.5);
    height: calc(var(--vh));
    background-color: var(--color-blue-850);
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }

  .nav__inner .nav__btns .btn--user:not(:last-child) {
    margin: 0 auto 10px;
  }
}

.nav__list {
  align-items: center;
  gap: 38px;
  display: flex;
}

@media only screen and (width <= 1280px) {
  .header .nav__list {
    margin-right: 10px;
  }
}

@media only screen and (width <= 992px) {
  .header .nav__list {
    z-index: -1;
    flex-direction: column;
  }
}

.nav__link {
  white-space: nowrap;
  text-transform: capitalize;
  color: var(--color-white);
  font-variation-settings: unset;
  background-color: #0000;
  border: 1px solid #0000;
  padding: 2px;
  font-size: 20px;
  line-height: 1.2;
  transition: color .4s, border .4s;
}

.nav__link:hover, .nav__link:focus, .nav__link.true {
  color: var(--color-blue-100);
}

.nav__btns {
  display: flex;
}

.nav__btns .btn--user {
  white-space: nowrap;
  color: var(--color-blue-900);
  border: 1px solid var(--color-blue-200);
  background-color: #0000;
  max-width: 141px;
}

.nav__btns .request-btn {
  white-space: nowrap;
  color: #fff;
  background-color: var(--color-blue-650);
  max-width: 145px;
}

@media only screen and (width <= 992px) {
  .nav__btns {
    flex-direction: column;
  }

  .nav .nav__list {
    margin: 0 auto;
  }

  .nav .nav__item {
    margin-right: 0;
  }

  .nav--footer {
    flex-direction: column;
  }
}

@media only screen and (width <= 992px) and (width <= 576px) {
  .nav--footer .nav__list {
    flex-direction: column;
  }
}

@media only screen and (width <= 992px) {
  .nav--footer .nav__inner {
    flex-direction: column;
    align-items: center;
  }
}

[class].btn {
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  white-space: nowrap;
  text-align: center;
  color: inherit;
  background-color: inherit;
  border: 1px solid #0000;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px 32px;
  font-size: 20px;
  line-height: 1;
  transition-property: background-color, color, border-color;
  transition-duration: .2s;
  display: flex;
  position: relative;
}

[class].btn:hover, [class].btn:focus {
  color: var(--color-blue-100);
}

[class].btn--reverse:hover, [class].btn--reverse:focus {
  background-color: #0000;
  border-image: url("../images/button-bg.png") 1 round;
  color: var(--color-blue-100) !important;
}

[class].btn--user {
  position: relative;
}

[class].btn--user:hover:before, [class].btn--user:focus:before {
  filter: invert();
}

@media only screen and (width <= 475px) {
  [class].btn {
    min-width: auto;
    margin: 0 auto;
  }
}

[class].request-btn {
  position: relative;
}

[class].request-btn:hover:after, [class].request-btn:focus:after {
  filter: invert(0);
}

.arrow {
  color: var(--color-blue-150);
  padding-right: 32px;
  transition: all .4s;
  display: inline-block;
  position: relative;
}

.arrow:after {
  content: "";
  background-image: url("../images/icons/arrow-next.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  transition: all .4s;
  position: absolute;
  top: 50%;
  right: -12px;
  transform: translateY(-50%);
}

.burger {
  --line-height: 2px;
  --buger-size: 25px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-white);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  width: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  height: var(--line-height);
  background-color: var(--lines-color);
  width: 100%;
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (width <= 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  gap: 40px;
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy {
  padding: calc(var(--header-height)  + 108px) 0 108px;
}

.policy__content {
  margin-bottom: 48px;
}

.policy__content:last-child {
  margin-bottom: 0;
}

.policy h1 {
  margin-bottom: 54px;
  padding-left: 64px;
  font-size: 32px;
}

.policy h2 {
  font-family: var(--font-family-third);
  font-weight: var(--fw-600);
  letter-spacing: .6px;
  margin: 0 0 24px;
  font-size: 30px;
}

.policy h2:before {
  display: none;
}

.policy p {
  color: var(--color-black);
  letter-spacing: .6px;
  font-size: 20px;
}

.policy p:not(:last-child) {
  margin-bottom: 24px;
}

.policy a {
  color: var(--color-blue-900);
  font-weight: var(--fw-700);
  text-decoration: underline;
}

.policy ul {
  margin: 0 0 24px;
  padding-top: 24px;
}

.policy li {
  color: var(--color-black);
  padding-left: 24px;
  font-size: 20px;
  position: relative;
}

.policy li:before {
  content: "✓";
  font-weight: var(--fw-600);
  color: var(--color-black);
  position: absolute;
  top: 0;
  left: 0;
}

.policy li span {
  font-weight: 800;
}

.policy li:last-child {
  margin-bottom: 0;
}

@media only screen and (width <= 992px) {
  .policy {
    padding: calc(var(--header-height)  + 40px) 0 40px;
  }
}

@media only screen and (width <= 576px) {
  .policy h1 {
    font-size: 44px;
  }
}

@media only screen and (width <= 768px) {
  .policy h1 {
    text-align: center;
    margin-bottom: 30px;
  }
}

.footer .contact {
  background-image: none;
  max-width: 526px;
  padding: 0;
}

.footer .contact:before {
  display: none;
}

.footer__main {
  justify-content: space-between;
  padding: 74px 0 74px 40px;
  display: flex;
}

.footer__inner {
  width: 100%;
  max-width: 526px;
}

.footer__inner p {
  font-weight: var(--fw-500);
  color: var(--color-white);
  margin-bottom: 19px;
  font-size: 22px;
}

.footer__inner a {
  color: var(--color-green-350);
  font-size: 16px;
  font-weight: 600;
}

.footer__inner a:not(:last-child) {
  margin-right: 48px;
}

.footer .logo {
  margin-bottom: 46px;
  display: block;
}

.footer form {
  display: flex;
}

.footer form input {
  max-width: 357px;
}

.footer form .btn {
  background-color: var(--color-white);
  border: 2px solid #0000;
  color: var(--color-black) !important;
}

.footer form .btn:hover, .footer form .btn:focus {
  color: var(--color-blue-150);
}

.footer__bottom {
  color: var(--color-white);
  justify-content: space-between;
  align-items: center;
  padding: 23px 0 23px 40px;
  display: flex;
}

.footer__bottom p {
  font-size: 16px;
}

.footer__policy {
  gap: 48px;
  margin-right: 47px;
  display: flex;
}

.footer__policy a {
  color: var(--color-white);
  transition: color .2s;
}

.footer__policy a:hover, .footer__policy a:focus {
  color: var(--color-blue-150);
}

.footer__contact span {
  font-size: 20px;
  line-height: 1.2;
  font-weight: var(--fw-500);
  color: var(--color-white);
  margin-right: 36px;
}

.footer__link {
  color: var(--color-white);
  font-size: 20px;
  line-height: 1.2;
  transition: color .2s;
}

.footer__link:hover, .footer__link:focus {
  color: var(--color-blue-150);
}

@media only screen and (width <= 1180px) {
  .footer__contact {
    max-width: 450px;
  }

  .footer .contact, .footer__inner {
    max-width: 500px;
  }
}

@media only screen and (width <= 992px) {
  .footer__contact {
    width: 100%;
    max-width: 550px;
  }

  .footer form input {
    max-width: 100%;
  }

  .footer .contact, .footer__inner {
    max-width: 550px;
  }

  .footer__main {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 40px 0;
  }

  .footer__bottom {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 0;
  }

  .footer__bottom p {
    text-align: center;
  }
}

@media only screen and (width <= 576px) {
  .footer form {
    flex-direction: column;
    gap: 10px;
  }

  .footer form .btn {
    max-width: unset;
    width: 100%;
  }
}

@media only screen and (width <= 475px) {
  .footer__link {
    font-size: 15px;
  }
}

.contact {
  background-image: url("../images/home/contact-bg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 108px 0 110px;
  position: relative;
  top: 0;
  left: 0;
}

.contact:before {
  content: "";
  z-index: 2;
  background-color: var(--color-black-80);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.contact .container {
  z-index: 3;
  position: relative;
}

.contact .title {
  color: var(--color-white);
  margin-bottom: 16px;
  padding-left: 64px;
}

.contact .title:before {
  background-image: url("../images/icons/snowflake-white.svg");
}

.contact h3 {
  font-family: var(--font-family-secondary);
  font-weight: var(--fw-400);
  margin-bottom: 86px;
  font-size: clamp(1.75rem, .885rem + 3.462vw, 4rem);
  line-height: 1.24;
}

.contact h3 span {
  color: var(--color-blue-125);
}

.contact__form {
  width: 100%;
  max-width: 703px;
  display: flex;
}

.contact label {
  width: 100%;
}

.contact input {
  font-weight: var(--fw-500);
  border: 1px solid var(--color-white);
  color: var(--color-gray-50);
  background-color: #0000;
  width: 100%;
  max-width: 534px;
  padding: 18px 32px;
  font-size: 20px;
  line-height: 1.2;
}

.contact input::placeholder {
  color: var(--color-white-opacity-80);
}

.contact .btn {
  background-color: var(--color-white);
  border: 2px solid #0000;
  max-width: 169px;
  padding: 15px 48px;
}

.contact .btn:hover, .contact .btn:focus {
  color: var(--color-blue-150);
}

@media only screen and (width <= 992px) {
  .contact h3 {
    margin-bottom: 40px;
  }
}

@media only screen and (width <= 768px) {
  .contact {
    padding: 40px 0;
  }
}

@media only screen and (width <= 576px) {
  .contact__form {
    flex-direction: column;
    gap: 10px;
  }

  .contact__form .btn {
    max-width: unset;
    width: 100%;
  }
}

.hero {
  min-height: 900px;
}

.hero h1 {
  font-family: var(--font-family-secondary);
  font-weight: var(--fw-400);
  z-index: 2;
  max-width: 753px;
  font-size: 136px;
  line-height: 1.24;
  position: absolute;
  top: 100px;
  right: -53px;
}

.hero h1 span {
  white-space: nowrap;
}

.hero__inner {
  padding-top: var(--header-height);
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 900px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.hero__inner--tours .hero__wrapper {
  gap: 97px;
}

.hero__inner--tours h1 {
  max-width: 680px;
  position: relative;
  top: 0;
  right: 0;
}

.hero__inner--tours .hero__content {
  padding-top: 187px;
}

.hero__inner--home h2 {
  text-transform: none;
}

.hero .hero__title--small {
  font-size: 100px;
}

.hero__intro {
  width: 100%;
  max-width: 821px;
  position: relative;
}

.hero__intro img {
  min-height: 650px;
}

.hero__intro:before {
  content: "";
  background-color: var(--color-black-50);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero__picture {
  width: 100%;
  max-width: 845px;
  position: relative;
}

.hero__picture img {
  min-height: 650px;
}

.hero__picture:before {
  content: "";
  background-color: var(--color-black-50);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero__picture--tours {
  max-width: 596px;
}

.hero__picture--tours:before {
  display: none;
}

.hero__wrapper {
  align-items: flex-start;
  gap: 107px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;
  display: flex;
}

.hero__wrapper--home {
  gap: 132px;
}

.hero__content {
  z-index: 1;
  width: 100%;
  max-width: 416px;
  height: 100%;
  padding-top: 50px;
  position: relative;
}

.hero__content--tours {
  max-width: 100%;
}

.hero__content h2 {
  color: var(--color-white);
  width: 100%;
  max-width: 322px;
  margin: 0 0 16px;
  font-size: 24px;
  line-height: 1.5;
}

.hero__content h2:before {
  display: none;
}

.hero__content p {
  color: var(--color-white);
  width: 100%;
  max-width: 322px;
  margin-bottom: 182px;
}

.hero__content .btn {
  white-space: nowrap;
  background-color: #0000;
  border: 4px solid #0000;
  border-image: url("../images/button-bg.png") 1 round;
  justify-content: flex-start;
  width: 100%;
  max-width: 416px;
  margin: 0;
  padding: 33px 75px;
  font-size: 32px;
  line-height: 1.2;
  position: relative;
  color: var(--color-white) !important;
}

.hero__content .btn:after {
  content: "";
  background-image: url("../images/icons/arrow-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 64px;
  height: 64px;
  display: block;
  position: absolute;
  top: 50%;
  right: 75px;
  translate: 0 -50%;
}

.hero--tour .hero__inner {
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  position: relative;
}

.hero--tour .hero__wrapper {
  justify-content: flex-end;
  gap: 146px;
}

.hero--tour a {
  font-size: 20px;
  line-height: 1.2;
  font-weight: var(--fw-500);
  color: var(--color-white);
  text-transform: none;
  margin-bottom: 34px;
  padding-left: 36px;
  display: inline-block;
}

.hero--tour a:before {
  background-image: url("../images/icons/arrow-back.svg");
  width: 24px;
  height: 24px;
}

.hero--tour h1 {
  font-family: var(--font-family-primary);
  font-size: 32px;
  font-weight: var(--fw-600);
  max-width: 630px;
  margin-bottom: 34px;
  line-height: 1.5;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  transform: translate(0);
}

.hero--tour .hero__content {
  max-width: 630px;
  padding-top: 124px;
}

.hero--tour p {
  max-width: 630px;
  font-size: 24px;
}

.hero--tour img {
  min-height: 619px;
}

.hero--tour .hero__picture {
  align-self: flex-end;
  max-width: 592px;
}

.hero--tour .hero__picture:before {
  display: none;
}

@media only screen and (width <= 1440px) {
  .hero h1 {
    font-size: clamp(2rem, .143rem + 9.286vw, 8.5rem);
  }

  .hero--tour h1 {
    font-size: 32px;
  }

  .hero .hero__title--small {
    font-size: clamp(2rem, .786rem + 6.071vw, 6.25rem);
  }

  .hero__title img {
    min-height: unset;
  }

  .hero__wrapper {
    padding: 40px 15px 40px 0;
  }

  .hero--tour .hero__wrapper {
    padding: 0 0 0 15px;
  }
}

@media only screen and (width <= 1280px) {
  .hero, .hero__inner {
    min-height: 750px;
  }

  .hero__intro img {
    min-height: 550px;
  }

  .hero--tour, .hero--tour .hero__inner {
    min-height: 850px;
  }

  .hero--tour .hero__wrapper {
    gap: 60px;
  }

  .hero__title img, .hero__picture img {
    aspect-ratio: 16 / 8;
    min-height: 500px;
  }

  .hero__title--tour img, .hero__picture--tour img {
    min-height: 619px;
  }

  .hero__content p {
    margin-bottom: 80px;
  }

  .hero__content--tour p {
    margin-bottom: 0;
  }
}

@media only screen and (width <= 1180px) {
  .hero__content {
    max-width: 350px;
  }

  .hero__content--tours {
    max-width: 100%;
  }

  .hero__content p {
    margin-bottom: 40px;
  }

  .hero__content--tour p {
    margin-bottom: 0;
  }

  .hero__content .btn {
    padding: 30px 48px;
  }

  .hero__content .btn:after {
    right: 38px;
  }
}

@media only screen and (width <= 992px) {
  .hero {
    min-height: 700px;
  }

  .hero__inner {
    min-height: 700px;
    position: relative;
  }

  .hero__wrapper {
    flex-direction: column;
    gap: 40px;
    padding: 40px 0;
  }

  .hero__wrapper--home .hero__content {
    max-width: 400px;
    padding: 0 15px;
  }

  .hero__wrapper--home .hero__content h2, .hero__wrapper--home .hero__content p {
    max-width: 400px;
  }

  .hero__intro {
    max-width: 100%;
  }

  .hero__intro img {
    min-height: unset;
    aspect-ratio: 16 / 12;
    width: 100%;
  }

  .hero--tour .hero__wrapper {
    padding: 0;
  }

  .hero h1 {
    text-align: center;
    top: 50%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
  }

  .hero__content, .hero__title {
    align-self: center;
  }

  .hero__title img, .hero__picture img {
    min-height: unset;
  }

  .hero__picture {
    max-width: 100%;
  }

  .hero__picture img {
    aspect-ratio: 16 / 12;
    width: 100%;
  }

  .hero__picture--tours {
    align-self: center;
  }

  .hero__inner--tours h1 {
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    transform: translate(0);
  }

  .hero__inner--tours .hero__wrapper {
    gap: 40px;
  }

  .hero__inner--tours .hero__content {
    text-align: center;
    align-self: center;
    padding: 0;
  }

  .hero--tour .hero__picture {
    order: 0;
    max-width: 100%;
  }

  .hero--tour .hero__content {
    order: 1;
    padding: 0 15px;
  }

  .hero--tour h1 {
    text-align: left;
    position: static;
    transform: translate(0);
  }
}

@media only screen and (width <= 768px) {
  .hero {
    min-height: 500px;
  }

  .hero__content, .hero__content h2, .hero__content p {
    max-width: unset;
  }

  .hero__content .btn {
    width: 100%;
    max-width: none;
  }

  .hero__inner--tours .hero__container {
    gap: 20px;
  }

  .hero__inner {
    min-height: 500px;
  }

  .hero--tour .hero__content a {
    margin-bottom: 20px;
    font-size: 26px;
  }

  .hero--tour .hero__content p {
    font-size: 20px;
  }

  .hero--tour h1 {
    margin-bottom: 20px;
    font-size: 26px;
  }
}

@media only screen and (width <= 576px) {
  .hero {
    min-height: 400px;
  }

  .hero__content {
    padding-top: 20px;
  }

  .hero__inner {
    min-height: 400px;
  }
}

@media only screen and (width <= 475px) {
  .hero__content .btn {
    padding: 30px 20px;
  }

  .hero__content .btn:after {
    right: 20px;
  }
}

.tours {
  padding: 72px 0 103px;
  position: relative;
}

.tours .title {
  margin-bottom: 16px;
  padding-left: 64px;
}

.tours .desc {
  color: var(--color-black-850);
  max-width: 587px;
  margin-bottom: 89px;
  font-size: 24px;
}

.tours h3 {
  color: var(--color-black);
  font-weight: var(--fw-700);
  text-align: center;
  margin: 0 auto 36px;
  font-size: 24px;
}

.tours img {
  margin-bottom: 30px;
}

.tours .swiper p {
  color: var(--color-black-50);
  text-align: center;
  margin-bottom: 39px;
  padding: 0 16px;
  line-height: 1.2;
}

.tours .swiper-slide {
  text-align: center;
}

.tours .swiper-slide a {
  font-size: 20px;
  font-weight: var(--fw-500);
  margin-bottom: 30px;
  line-height: 1.2;
}

.tours .swiper-slide a:after {
  right: 0;
}

.tours .swiper-button-prev, .tours .swiper-button-next {
  position: relative;
}

.tours .swiper-button-prev:after, .tours .swiper-button-next:after {
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  width: 52px;
  height: 52px;
  position: absolute;
}

.tours .swiper-pagination {
  bottom: 88px;
}

.tours .swiper-pagination--home {
  bottom: 109px;
}

.tours .swiper-pagination-bullet {
  background-color: var(--color-blue-150);
  width: 16px;
  height: 16px;
  bottom: 32px;
}

.tours .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 16px;
}

.tours .swiper-button-prev {
  top: 44px;
  left: 39.5%;
}

.tours .swiper-button-prev--home {
  top: 22px;
}

.tours .swiper-button-prev:after {
  background-image: url("../images/icons/arrow-prev.svg");
}

.tours .swiper-button-next {
  top: 23px;
  left: 58.3%;
}

.tours .swiper-button-next--home {
  top: 2px;
}

.tours .swiper-button-next:after {
  background-image: url("../images/icons/arrow-next.svg");
}

@media only screen and (width <= 992px) {
  .tours .swiper-button-prev:after, .tours .swiper-button-next:after {
    width: 42px;
    height: 42px;
  }

  .tours .swiper-button-prev {
    left: 39%;
  }

  .tours .swiper-button-next {
    left: 58%;
  }

  .tours .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 6px;
  }

  .tours .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }
}

@media only screen and (width <= 768px) {
  .tours .swiper-button-prev {
    left: 36%;
  }

  .tours .swiper-button-next {
    left: 59%;
  }
}

@media only screen and (width <= 576px) {
  .tours .swiper-button-prev {
    left: 30%;
  }

  .tours .swiper-button-next {
    left: 65%;
  }
}

@media only screen and (width <= 475px) {
  .tours .swiper-button-prev {
    left: 27%;
  }

  .tours .swiper-button-next {
    left: 65%;
  }
}

.about__info {
  padding: 72px 57px 181px;
}

.about .title {
  color: var(--color-white);
  margin-bottom: 16px;
  padding-left: 64px;
}

.about .title:before {
  background-image: url("../images/icons/snowflake-white.svg");
}

.about .description {
  color: var(--color-white);
  width: 100%;
  max-width: 962px;
}

.about__images {
  background: var(--color-white-50);
  grid-template-columns: 1fr 1.69fr 1fr;
  gap: 24px;
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 127px;
  display: grid;
}

.about__images img {
  z-index: 3;
  box-shadow: 0 10px 25px var(--color-black-50);
  position: relative;
  transform: translateY(-59px);
}

@media only screen and (width <= 768px) {
  .about__images {
    padding-bottom: 40px;
  }
}

@media only screen and (width <= 576px) {
  .about__images {
    padding-bottom: 20px;
  }

  .about .description {
    font-size: 20px;
  }

  .about__info {
    padding: 50px 20px 110px;
  }
}

.discover {
  padding: 72px 0 176px;
}

.discover h2 {
  font-family: var(--font-family-secondary);
  font-weight: var(--fw-400);
  text-transform: lowercase;
  margin-bottom: 99px;
  font-size: clamp(2.25rem, 7.619vw - .607rem, 6.25rem);
  line-height: 1.24;
}

.discover h2:before {
  display: none;
}

.discover__images {
  grid-template-columns: 1fr;
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  overflow-x: hidden;
}

@media only screen and (width <= 992px) {
  .discover {
    padding: 40px 0;
  }

  .discover h2 {
    margin-bottom: 20px;
  }
}

@media only screen and (width <= 475px) {
  .discover h2 {
    font-size: 28px;
  }
}

.hero-tour__inner {
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  position: relative;
}

.hero-tour a {
  color: var(--color-white);
  text-transform: none;
  margin-bottom: 34px;
  padding-left: 36px;
  display: inline-block;
}

.hero-tour a:before {
  background-image: url("../images/icons/arrow-back.svg");
  width: 24px;
  height: 24px;
}

.hero-tour h1 {
  font-family: var(--font-family-primary);
  font-size: 32px;
  font-weight: var(--fw-600);
  max-width: 630px;
  margin-bottom: 34px;
  line-height: 1.5;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  transform: translate(0);
}

.hero-tour__content {
  max-width: 630px;
  padding-top: 113px;
}

.hero-tour p {
  max-width: 630px;
  font-size: 24px;
}

.hero-tour img {
  min-height: 619px;
  position: relative;
  right: -72px;
}

.hero-tour__picture {
  align-self: flex-end;
  max-width: 592px;
}

.hero-tour__picture:before {
  display: none;
}

@media only screen and (width <= 1440px) {
  .hero-tour .container {
    padding-right: 0;
  }

  .hero-tour img {
    min-height: 619px;
    position: relative;
    right: 0;
  }
}

@media only screen and (width <= 992px) {
  .hero-tour .container {
    padding: 0;
  }

  .hero-tour__content {
    padding: 113px 15px 0;
  }

  .hero-tour p {
    margin-bottom: 40px;
  }

  .hero-tour__inner {
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
  }

  .hero-tour__picture {
    max-width: unset;
    align-self: center;
  }

  .hero-tour__picture img {
    min-height: unset;
    aspect-ratio: 16 / 14;
    width: 100%;
    max-width: 630px;
    margin: 0 auto;
  }
}

.activities img {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.activities__detailed {
  margin-bottom: 48px;
}

.activities__detailed li {
  color: var(--color-black-80);
  font-size: 24px;
  line-height: 1.5;
}

.activities__detailed li:not(:last-child) {
  margin-bottom: 36px;
}

.activities__content {
  padding: 86px 0;
}

.activities__content h2 {
  color: var(--color-black-900);
  text-transform: none;
  margin-bottom: 24px;
  line-height: 1.3;
}

.activities__content h2:before {
  display: none;
}

.activities__content p {
  color: var(--color-black-80);
}

.activities__content p:not(:last-child) {
  margin-bottom: 48px;
}

.activities__content img {
  max-width: 1200px;
  margin-bottom: 48px;
}

.insights {
  padding: 86px 0;
}

.insights__wrapper {
  grid-template-columns: 1fr 1fr;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;
  display: grid;
}

.insights__content {
  background-color: var(--color-blue-175);
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 720px;
  min-height: 502px;
  margin-top: 32px;
  padding: 90px 124px;
  display: flex;
}

.insights img {
  width: 100%;
  max-width: 720px;
}

.insights h2 {
  font-weight: var(--fw-600);
  color: var(--color-white);
  text-transform: none;
  margin-bottom: 16px;
  font-size: 24px;
}

.insights h2:before {
  display: none;
}

.insights p {
  color: var(--color-white);
  width: 100%;
  max-width: 472px;
}

.insights__content--light {
  background-color: var(--color-white-75);
  color: var(--color-black-900);
}

.insights__content--light h2, .insights__content--light p {
  color: var(--color-black-900);
}

@media only screen and (width <= 1280px) {
  .insights__content {
    min-height: unset;
    padding: 70px 90px;
  }
}

@media only screen and (width <= 1180px) {
  .insights__content {
    min-height: unset;
    padding: 40px 50px;
  }
}

@media only screen and (width <= 992px) {
  .insights {
    padding: 20px 0;
  }

  .insights img {
    max-width: unset;
    aspect-ratio: 16 / 6;
  }

  .insights__wrapper {
    grid-template-columns: 1fr;
  }

  .insights__content {
    max-width: unset;
    margin-top: 0;
    padding: 40px 20px;
  }

  .insights p {
    max-width: unset;
  }
}

.cultural {
  padding: 86px 0 65px;
}

.cultural__insights {
  border-bottom: 2px solid var(--color-gray-75);
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 64px;
  padding-bottom: 91px;
  display: flex;
}

.cultural__content {
  width: 100%;
  max-width: 421px;
}

.cultural__content h2 {
  margin-bottom: 16px;
  line-height: 1.5;
}

.cultural__content h2:before {
  display: none;
}

.cultural__content p {
  color: var(--color-black-850);
  font-size: 20px;
}

.cultural__items {
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  display: grid;
}

.cultural__item {
  width: 100%;
  max-width: 380px;
  padding-top: 62px;
}

.cultural__item .title {
  color: var(--color-blue-800);
  text-transform: none;
  margin-bottom: 16px;
  font-size: 24px;
}

.cultural__item .title:before {
  background-image: url("../images/icons/snowflake-grey.svg");
  width: 38px;
  height: 38px;
  top: -62px;
  transform: translateY(0);
}

.cultural__item p {
  color: var(--color-black-850);
  max-width: 370px;
}

@media only screen and (width <= 1180px) {
  .cultural img {
    max-width: 500px;
  }
}

@media only screen and (width <= 992px) {
  .cultural {
    padding: 40px 0;
  }

  .cultural__insights {
    flex-direction: column;
    padding-bottom: 40px;
  }

  .cultural img {
    max-width: unset;
    width: 100%;
  }

  .cultural__content {
    max-width: unset;
  }
}

@media only screen and (width <= 768px) {
  .cultural__items {
    grid-template-columns: repeat(1, 1fr);
  }

  .cultural__item, .cultural__item p {
    max-width: unset;
  }
}

.signature {
  padding: 72px 0 118px;
}

.signature .title {
  margin-bottom: 16px;
  padding-left: 64px;
}

.signature .title:before {
  background-image: url("../images/icons/snowflake.svg");
}

.signature .description {
  color: var(--color-black-850);
  width: 100%;
  max-width: 709px;
  margin-bottom: 87px;
}

.signature__items {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.signature__items img {
  min-height: 480px;
  margin-bottom: 30px;
}

.signature__items h3 {
  text-align: center;
  color: var(--color-black);
  font-weight: var(--fw-700);
  margin-bottom: 40px;
  font-size: 24px;
  line-height: 1.5;
}

.signature__items a {
  font-weight: var(--fw-500);
  font-size: 20px;
  line-height: 1.2;
}

.signature__items a:after {
  right: 0;
}

.signature__items a:hover, .signature__items a:focus {
  color: var(--color-black);
}

.signature__items a:hover:after, .signature__items a:focus:after {
  background-image: url("../images/icons/arrow-next-black.svg");
}

.signature__item {
  text-align: center;
}

@media only screen and (width <= 1280px) {
  .signature__items h3 {
    max-width: 320px;
    margin: 0 auto 30px;
  }
}

@media only screen and (width <= 992px) {
  .signature__items {
    grid-template-columns: 1fr;
  }

  .signature__items img {
    max-width: unset;
    aspect-ratio: 16 / 8;
    width: 100%;
  }

  .signature__items h3 {
    max-width: unset;
  }
}

@media only screen and (width <= 576px) {
  .signature {
    padding: 40px 0;
  }
}

.featured {
  padding: 72px 0;
}

.featured .title {
  margin-bottom: 98px;
  padding-left: 64px;
}

.featured .title:before {
  background-image: url("../images/icons/snowflake.svg");
}

.featured [hidden] {
  display: none;
}

.featured__hidding {
  border-bottom: 1px solid var(--color-black-30);
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  display: grid;
}

.featured [role="tab"] {
  font-weight: var(--fw-500);
  text-align: center;
  color: var(--color-black-30);
  background-color: #0000;
  border-bottom: 2px solid #0000;
  width: 100%;
  padding: 0 0 18px;
  font-size: 20px;
  line-height: 1.27;
  transition: all .4s;
  position: relative;
}

.featured [role="tab"]:hover, .featured [role="tab"]:focus {
  color: var(--color-black);
}

.featured button[aria-selected="true"] {
  border-bottom: 2px solid var(--color-black);
}

.featured__content {
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  min-height: 400px;
  padding-top: 86px;
  display: flex;
}

.featured__information {
  text-align: left;
  max-width: 600px;
}

.featured__information h3 {
  text-align: left;
  font-size: 24px;
  font-weight: var(--fw-700);
  color: var(--color-black);
  margin-bottom: 16px;
  line-height: 1.5;
}

.featured__information p {
  color: var(--color-black-50);
  margin-bottom: 16px;
  line-height: 1.2;
}

.featured__information p:not(:last-of-type) {
  font-weight: var(--fw-700);
  color: var(--color-black-30);
}

.featured__information .btn {
  text-align: start;
  max-width: 117px;
  margin: 0;
  padding: 4px;
  display: inline-block;
  color: var(--color-blue-150) !important;
}

.featured__information .btn:after {
  right: 4px;
}

.featured__information .btn:hover, .featured__information .btn:focus {
  color: var(--color-black) !important;
}

.featured__information .btn:hover:after, .featured__information .btn:focus:after {
  background-image: url("../images/icons/arrow-next-black.svg");
}

@media only screen and (width <= 992px) {
  .featured__content img {
    aspect-ratio: 16 / 10;
    max-width: 400px;
  }
}

@media only screen and (width <= 768px) {
  .featured__hidding {
    gap: 20px;
  }

  .featured [role="tab"] {
    font-size: 16px;
  }

  .featured__content {
    flex-direction: column;
  }

  .featured__content img {
    order: 0;
    width: 100%;
    max-width: 100%;
  }

  .featured__information {
    order: 1;
    max-width: 100%;
  }
}

@media only screen and (width <= 576px) {
  .featured__content {
    padding-top: 40px;
  }
}
/*# sourceMappingURL=main.css.map */
