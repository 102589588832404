@use '../../utils/functions/' as *;
@use '../../utils/mixins/' as *;


.tours {
  padding: 72px 0 103px;
  position: relative;

  .title {
    padding-left: 64px;
    margin-bottom: 16px;
  }

  .desc {
    font-size: 24px;
    color: var(--color-black-850);
    margin-bottom: 89px;
    max-width: 587px;
  }

  h3 {
    color: var(--color-black);
    font-weight: var(--fw-700);
    font-size: 24px;
    text-align: center;
    margin: 0 auto 36px;
  }

  img {
    margin-bottom: 30px;
  }

  .swiper {

    p {
      color: var(--color-black-50);
      line-height: 1.2;
      margin-bottom: 39px;
      text-align: center;
      padding: 0 16px;
    }
  }

  .swiper-slide {
    text-align: center;

    a {
      font-size: 20px;
      font-weight: var(--fw-500);
      margin-bottom: 30px;
      line-height: 1.2;

      &::after {
        right: 0;
      }
    }
  }

  .swiper-button-prev, .swiper-button-next {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      width: 52px;
      height: 52px;
    }
  }

  .swiper-pagination {
    bottom: 88px;

    &--home {
      bottom: 109px;
    }
  }

  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background-color: var(--color-blue-150);
    bottom: 32px;
  }

  .swiper-pagination-bullets {

    .swiper-pagination-bullet  {
      margin: 0 16px;
    }
  }

  .swiper-button-prev {
    top: 44px;
    left: 39.5%;

    &--home {
      top: 22px;
    }

    &::after {
      background-image: url('../images/icons/arrow-prev.svg');

    }
  }

  .swiper-button-next{
    top: 23px;
    left: 58.3%;

    &--home {
      top: 2px;
    }

    &::after {
      background-image: url('../images/icons/arrow-next.svg');
    }
  }

  @include media(tablet) {

    .swiper-button-prev, .swiper-button-next {

      &::after {
        width: 42px;
        height: 42px;
      }
    }

    .swiper-button-prev{
      left: 39%;
    }

    .swiper-button-next {
      left: 58%;
    }

    .swiper-pagination-bullets {

      .swiper-pagination-bullet  {
        margin: 0 6px;
      }
    }

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
    }
  }

  @include media(mobile-l) {


    .swiper-button-prev{
      left: 36%;
    }

    .swiper-button-next {
      left: 59%;
    }
  }

  @include media(mobile-m) {

    .swiper-button-prev{
      left: 30%;
    }

    .swiper-button-next {
      left: 65%;
    }
  }

  @include media(mobile-s) {

    .swiper-button-prev{
      left: 27%;
    }

    .swiper-button-next {
      left: 65%;
    }
  }
}